import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import {
  Button,
  IconButton,
  Input,
  Typography,
  Spinner,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { useBVN } from "./useBvn";
import { useState } from "react";
import { toast } from "react-toastify";

function Menu() {
  const { bvnQuery, isPending } = useBVN();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [resp, setResp] = useState({});
  function onSubmit({ bvn }) {
    console.log(bvn);
    bvnQuery(
      { bvn },
      {
        onSuccess: (response) => {
          console.log(response);
          if (response.statusCode === "200") setResp(response.data);
          else toast.error(response.statusDescription);
        },
        onSettled: () => reset,
      }
    );
  }
  return (
    <div className="grid h-full grid-cols-1 gap-2  divide-x lg:h-full lg:grid-cols-3">
      <div>
        <div className="col-start-1 mx-auto lg:w-full">
          <div className="mx-auto mt-2 place-content-center justify-center px-5 text-center">
            <Typography className="text-center my-5 text-black">
              Query Bank Verification Number
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                {...register("bvn", {
                  required: "Bank Verification Number field is required",
                  minLength: {
                    value: 11,
                    message: "BVN must be 11 digits characters",
                  },
                })}
                size="lg"
                disabled={isPending}
                className="rounded !border !border-gray-300 !border-t-blue-gray-200 bg-white text-secondary shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:ring-gray-900/10"
                placeholder={"Enter your BVN "}
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              {errors?.bvn && (
                <Typography color="red" className="justify-start text-xs">
                  {errors?.bvn?.message}
                </Typography>
              )}
              <Button
                disabled={isPending}
                type="submit"
                variant="gradient"
                className="my-5 bg-primary"
                fullWidth
              >
                {isPending ? <Spinner /> : "Query"}
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div>
        <div className="mx-auto lg:w-full">
          <div className="mx-auto mt-2 px-1  text-black">
            <div className="grid">
              <div className="col-start-1 row-start-1 h-5/6">
                <div className="mx-auto lg:w-full">
                  <div className="mx-auto py-1">
                    <div className="flex py-2">
                      <div className="flex-1">
                        <IconButton className="rounded-full lg:size-40">
                          <DevicePhoneMobileIcon className="m-2 size-10 fill-black stroke-white" />
                        </IconButton>
                      </div>
                    </div>
                    <div className="mx-auto mt-2">
                      <div className="grid">
                        <div className="mt-3 flex justify-between border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              Bank Verification Number
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.BVN ?? "NA"}
                            </Typography>
                          </div>
                        </div>

                        <div className=" mt-3 justify-between flex border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              First Name
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.FirstName ?? "NA"}
                            </Typography>
                          </div>
                        </div>

                        <div className=" mt-3 justify-between flex border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              Middle Name
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.OtherNames ?? "NA"}
                            </Typography>
                          </div>
                        </div>
                        <div className=" mt-3 justify-between flex border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              Last Name
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.LastName ?? "NA"}
                            </Typography>
                          </div>
                        </div>
                        <div className=" mt-3 justify-between flex border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              Date Of Birth
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.DOB ?? "NA"}
                            </Typography>
                          </div>
                        </div>
                        <div className=" mt-3 justify-between flex border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              Phone Number
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.phoneNumber ?? "NA"}
                            </Typography>
                          </div>
                        </div>
                        <div className=" mt-3 justify-between flex border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              Registration Date
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.regdate ?? "NA"}
                            </Typography>
                          </div>
                        </div>
                        <div className=" mt-3 justify-between flex border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              Enrollment Bank
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.bank ?? "NA"}
                            </Typography>
                          </div>
                        </div>
                        <div className=" mt-3 justify-between flex border-b border-gray-500 p-1.5">
                          <div className="">
                            <Typography className="font-light capitalize">
                              Enrollment Branch
                            </Typography>
                          </div>
                          <div className="flex flex-row-reverse">
                            <Typography className="flex font-light">
                              {resp?.branch ?? "NA"}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
