import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUser } from "../hooks/useUser";

function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";
  // 1. Load the authenticated user
  const { isLoading, isAuthenticated } = useUser();
  useEffect(
    function () {
      if (!isAuthenticated && !isLoading) navigate(from, { replace: true });
    },
    [isAuthenticated, isLoading, navigate, from]
  );

  // 4. If there IS a user, render the app
  if (isAuthenticated) return children;
}

export default ProtectedRoute;
