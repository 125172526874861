import { Typography } from "@material-tailwind/react";
import pinball from "../../assets/pinball.png";
import light from "../../assets/light.png";

function MissionAndVision() {
  return (
    <div
      id="about-us"
      className="grid gap-10 px-10 py-10 lg:flex text-black lg:px-20 lg:gap-20"
    >
      <div className="">
        <img src={pinball} alt="Misson" className="size-20 lg:size-40" />
        <Typography variant="h6" className="text-orange-600 my-5">
          Our Mission
        </Typography>
        <Typography variant="h2" className="my-3">
          Providing Inclusive Financial Services
        </Typography>
        <Typography variant="paragraph" className="text-xl">
          Our mission is to provide inclusive financial services that empower
          individuals, particularly those in underserved communities, to improve
          their quality of life and contribute to economic development. We aim
          to create a financially inclusive society by offering accessible and
          affordable financial products and services tailored to meet the unique
          needs of our clients.
        </Typography>
      </div>
      <div className="">
        <img src={light} alt="statement" className="size-20 lg:size-40" />
        <Typography variant="h6" className="text-orange-600 my-5">
          Our Vision
        </Typography>
        <Typography variant="h2" className="my-3">
          We are Passionate about Creating Opportunities
        </Typography>
        <Typography variant="paragraph" className="text-xl">
          We envision a future where every individual, regardless of their
          socio-economic background or geographic location, has equal access to
          financial resources and opportunities. By leveraging technology,
          fostering partnerships, and nurturing a culture of financial literacy,
          we strive to be a catalyst for positive change, enabling our clients
          to build a better future for themselves and their communities.
        </Typography>
      </div>
    </div>
  );
}

export default MissionAndVision;
