import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Util } from "../Util/Utils";
import { loginAction } from "../services/AuthServices";

export const useLogin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";
  const {
    mutate: login,
    isPending,
    error,
  } = useMutation({
    mutationFn: loginAction,
    onSuccess: (user) => {
      console.info(user);
      queryClient.setQueryData(["user"], {
        token: user.data.idToken,
        expiresIn: new Date(
          new Date().getTime() + parseInt(user.data.expiresIn) * 1000
        ).toISOString(),
        user: user.data.user,
      });
      navigate(from, { replace: true });
    },
    onError: (error) => {
      console.error(error);
      toast.error(Util.formatError(error));
    },
  });
  return { login, isPending, error };
};
