import bg from "../../assets/babe.png";
import bg1 from "../../assets/tailor.png";
import bg2 from "../../assets/man_in_office.png";
import bg3 from "../../assets/deal.png";
function Community() {
  return (
    <div className="grid my-10">
      <div className="grid lg:flex">
        <div
          style={{ backgroundImage: `url(${bg})`, backgroundColor: "pink" }}
          className="h-96 bg-cover bg-no-repeat bg-center lg:bg-cover lg:h-screen w-full lg:w-1/2"
        ></div>
        <div
          style={{ backgroundImage: `url(${bg1})` }}
          className="h-96 bg-cover bg-no-repeat bg-center lg:bg-cover lg:h-screen w-full lg:w-1/2"
        ></div>
      </div>
      <div className="grid lg:flex">
        <div
          style={{ backgroundImage: `url(${bg2})` }}
          className="h-96 bg-cover bg-no-repeat bg-center lg:bg-cover lg:h-screen w-full lg:w-1/2"
        ></div>
        <div
          style={{ backgroundImage: `url(${bg3})` }}
          className="h-96 bg-cover bg-no-repeat bg-center lg:bg-cover lg:h-screen w-full lg:w-1/2"
        ></div>
      </div>
    </div>
  );
}

export default Community;
