import "./App.css";
import "tailwindcss/tailwind.css";
import AppLayout from "./components/AppLayout";
import Home from "./components/Home";
import Login from "./components/Login/Login";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MenuLayout from "./components/Menu/MenuLayout";
import Menu from "./components/Menu/Menu";
import ProtectedRoute from "./components/Menu/ProtectedRoute";
import Privacy from "./components/Privacy";
import TermsAndCondition from "./components/TermsAndCondition";

const routers = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        element: <Home />,
        path: "",
      },
      {
        element: <Privacy />,
        path: "privacy-policy",
      },
      {
        element: <TermsAndCondition />,
        path: "term-and-conditions",
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        element: (
          <ProtectedRoute>
            <MenuLayout />
          </ProtectedRoute>
        ),
        path: "/dashboard",
        children: [
          {
            path: "query-bvn",
            element: <Menu />,
          },
        ],
      },
    ],
  },
]);
function App() {
  return <RouterProvider router={routers} />;
}
export default App;
