import { useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "../services/AuthServices";

export function useUser() {
  const { isLoading, data } = useQuery({
    queryKey: ["user"],
    queryFn: getCurrentUser,
  });
  console.log(data);
  return {
    isLoading,
    data,
    isAuthenticated: new Date(data?.expiresIn) > new Date(),
  };
}
