import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Util } from "../Util/Utils";
import { getBvn } from "../services/AuthServices";

export const useBVN = () => {
  const { mutate: bvnQuery, isPending } = useMutation({
    mutationFn: getBvn,
    onError: (error) => {
      console.error(error);
      toast.error(Util.formatError(error));
    },
  });
  return { bvnQuery, isPending };
};
