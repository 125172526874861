import { Typography } from "@material-tailwind/react";
import { FeaturedImage } from "./widgets/FeaturedImage";

function Privacy() {
  return (
    <>
      <FeaturedImage title="Privacy & Policy" />
      <div className="gap-5 grid  px-20 py-5  mx-auto text-[#1b2441]">
        <div className="grid">
          <Typography variant="h4" className="py-2">
            Privacy Policy
          </Typography>
          <Typography variant="paragraphy">
            This Privacy Policy (“Policy”) sets out the basis on which any
            personal data we collect from you or that you provide us will be
            processed by us, including the type of information being collected,
            the method of such information collection, the use of such
            information, the protection of such information, and the sharing of
            such information with third parties. The policy applies to all the
            products, subscriber-based services, mobile applications
            (“services”), and websites offered by FOCUS Microfinance Bank
            Limited. We encourage you to review the privacy policy whenever you
            interact with us to stay informed about our information practices
            and the ways you can help protect your privacy.
          </Typography>
        </div>
        <div className="grid">
          <Typography variant="h4" className="py-2">
            INFORMATION WE COLLECT
          </Typography>
          <Typography variant="paragraphy">
            When you use our websites and services, we collect and store your
            personal information, which you provide from time to time. Personal
            information in this context shall include all data such as: full
            legal name, bank verification number, phone number, means of
            identification, identification number, mailing address, email
            address, tax ID, a photo, biometric information, occupation, assets,
            income, location data, an online identifier, and other unique
            identifiers such as but not limited to MAC address, IP address, IMEI
            number, IMSI number, and SIM.
          </Typography>
          <Typography variant="paragraphy" className="py-1">
            We collect information when you create an account, subscribe,
            participate in any interactive features of our services, fill out a
            form, apply for a loan, use your credit or debit cards, request
            customer support, or otherwise communicate with us.
          </Typography>
          <Typography variant="paragraphy" className="py-1">
            We may obtain information through our mobile applications that you
            install on your mobile devices to access and use our services. We
            may also collect other information, such as video footage of you
            whenever you step into any of our branches, telephone conversations
            when you call any of our contact Centre lines, or geographic
            information.
          </Typography>
          <Typography variant="paragraphy" className="py-1">
            We are the custodians of data on behalf of the users that use our
            services, and we do not own data; users own their data. We will
            store the data provided for only the period within which it is
            reasonably needed.
          </Typography>
        </div>
        <div className="grid">
          <Typography variant="h4" className="py-2">
            WHAT WE DO WITH YOUR INFORMATION
          </Typography>
          <Typography variant="paragraphy" className="py-1">
            The collection and use of personal data by FOCUS Microfinance Bank
            is guided by certain principles. These principles state that
            personal data should:
          </Typography>
          <ul className="list-disc grid py-1 pl-5 gap-2">
            <li className="">
              Be processed fairly, lawfully, and in a transparent manner.
            </li>
            <li>
              {" "}
              Be obtained for a specified and lawful purpose and shall not be
              processed in any manner incompatible with such purposes.
            </li>
            <li>
              Be adequate, relevant, and limited to what is necessary to fulfil
              the purpose of processing.
            </li>
            <li>
              {" "}
              Be accurate and, where necessary, up-to-date. In the event that
              data is inaccurate, steps should be taken to rectify or erase such
              data.
            </li>
            <li>
              {" "}
              Not to be kept for longer than necessary for the purpose of
              processing.
            </li>
            <li>
              Be processed in accordance with the data subject's rights, and •
            </li>
            <li>
              Be kept safe from unauthorized processing and accidental loss,
              damage, or destruction using adequate technical and organizational
              measures.
            </li>
          </ul>
          <div>
            <Typography variant="paragraphy" className="py-1">
              We will only use your information when you have provided your
              consent or when we are required by law to do so. These include:
            </Typography>
            <ul className="list-disc grid py-1 pl-5 gap-2">
              <li className="">
                Cases where processing of personal data is required for the
                fulfilment of a contractual obligation.
              </li>
              <li>
                Cases where processing of personal data is required for
                compliance with legal and/or regulatory requirements.
              </li>
              <li>
                Cases where processing is required to protect your vital
                interest or that of any other natural person.
              </li>
              <li>
                Cases where processing is required for an activity to be carried
                out in significant public interest.
              </li>
              <li>
                Cases where processing is required for the legitimate interests
                of FOCUS Microfinance Bank or a third party, insofar as this
                does not conflict with the requirements for the protection of
                your personal data.
              </li>
            </ul>
          </div>
          <div>
            <Typography variant="paragraphy" className="py-1">
              We will only use your information when you have provided your
              consent or when we are required by law to do so. These include:
            </Typography>
            <ul className="list-disc grid py-1 pl-5 gap-2">
              <li className="">
                Cases where processing of personal data is required for the
                fulfilment of a contractual obligation.
              </li>
              <li>
                Cases where processing of personal data is required for
                compliance with legal and/or regulatory requirements.
              </li>
              <li>
                Cases where processing is required to protect your vital
                interest or that of any other natural person.
              </li>
              <li>
                Cases where processing is required for an activity to be carried
                out in significant public interest.
              </li>
              <li>
                Cases where processing is required for the legitimate interests
                of FOCUS Microfinance Bank or a third party, insofar as this
                does not conflict with the requirements for the protection of
                your personal data.
              </li>
            </ul>
          </div>
        </div>

        <div className="grid">
          <Typography variant="h4" className="py-2">
            SECURITY OF INFORMATION
          </Typography>
          <Typography variant="paragraphy">
            We adopt appropriate technical, data collection, storage, and
            processing practices and security measures to protect against
            unauthorized access, misuse, alteration, disclosure, or destruction
            of your personal information, username, password, transaction
            information, and data under our control. This may include the use of
            encryption, access controls, and other forms of security to ensure
            that your data is protected. We require all parties, including our
            staff and third parties processing data on our behalf, to comply
            with relevant policies and guidelines to ensure confidentiality and
            that information is protected in use, when stored, and during
            transmission. Our security controls and processes are also regularly
            updated to meet and exceed industry standards. Where we have
            provided you (or where you have chosen) a password that grants you
            access to specific areas of our site, you are responsible for
            keeping this password confidential. We request that you do not share
            your password or other authentication details (e.g., token-generated
            codes) with anyone.
          </Typography>
        </div>

        <div className="grid">
          <Typography variant="h4" className="py-2">
            DATA RETENTION
          </Typography>
          <Typography variant="paragraphy">
            We retain your data for as long as it is necessary for the
            purpose(s) for which it was collected. The storage of your data is
            also determined by legal, regulatory, administrative, or operational
            requirements. We only retain information that allows us to comply
            with legal and regulatory requests for certain data, meet business
            and audit requirements, respond to complaints and queries, or
            address disputes or claims that may arise. Data that is not retained
            is securely destroyed when it is identified as no longer needed for
            the purposes for which it was collected.
          </Typography>
        </div>

        <div className="grid">
          <Typography variant="h4" className="py-2">
            CROSS-BORDER PERSONAL DATA TRANSFERS
          </Typography>
          <Typography variant="paragraphy">
            FOCUS Microfinance Bank is committed to protecting customers’
            personal information regardless of where the data resides and to
            providing appropriate protection for where such data is transferred
            across borders, including outside of Nigeria. To ensure that these
            transfers are in line with permissible conditions outlined by the
            Nigerian Data Protection Regulation, we have taken appropriate and
            suitable safeguards to ensure that personal data will remain
            protected when cross-border transfers occur. These safeguards
            include transferring personal data to countries with adequate data
            protection regulations and ensuring that standard contractual
            clauses are in place to mandate that personal data is secured using
            best practices.
          </Typography>
        </div>

        <div className="grid">
          <Typography variant="h4" className="py-2">
            COOKIES
          </Typography>
          <Typography variant="paragraphy">
            We use data collection devices such as “cookies” on our website and
            applications. “Cookies” are small files stored on your hard drive
            that assist us in providing services customized to your requirements
            and tastes. You are always free to decline our cookies if your
            browser permits, although in that case you may not be able to use
            certain features on our website and application, and you may be
            required to re-enter your password more frequently during a session.
            A cookie cannot read data off your hard disc or read cookie files
            created by other sites. The use of a cookie is in no way linked to
            any personally identifiable information.
          </Typography>
        </div>
        <div className="grid">
          <Typography variant="h4" className="py-2">
            THIRD-PARTY SITES AND SERVICES
          </Typography>
          <Typography variant="paragraphy">
            FOCUS Microfinance Bank’s websites, products, applications, and
            services may contain links to third-party websites, products, and
            services. Our products and services may also use or offer products
            or services from third parties. Information collected by third
            parties, which may include such things as location data or contact
            details, is governed by their privacy practices, and we will not be
            liable for any breach of confidentiality or privacy of your
            information on such sites. We encourage you to learn about the
            privacy practices of those third parties.
          </Typography>
        </div>
        <div className="grid">
          <Typography variant="h4" className="py-2">
            DISCLOSURE OF INFORMATION
          </Typography>
          <Typography variant="paragraphy">
            Due to the existing regulatory environment, we cannot ensure that
            all your private communications and other personally identifiable
            information will never be disclosed in ways not otherwise described
            in this policy. By way of example (without limiting and foregoing),
            we may be forced to disclose information to the government, law
            enforcement agencies, our parent and/or subsidiaries, and third
            parties for the performance of a task carried out in the interest of
            the public, for the protection of your vital interests, for the
            performance of a contract to which you are a party, and also where
            you have expressly given us written consent to disclose the same.
          </Typography>
        </div>
        <div className="grid">
          <Typography variant="h4" className="py-2">
            YOUR INFORMATION AND YOUR RIGHTS
          </Typography>
          <Typography variant="paragraphy">
            You have the following rights:
          </Typography>
          <ul className="list-disc grid py-1 pl-5 gap-2">
            <l>
              The right to be told how we use your information and obtain access
              to your information.
            </l>{" "}
            <l>
              The right to have your information rectified or erased, or to
              place restrictions on processing your information.
            </l>{" "}
            <l>
              The right to object to the processing of your information, e.g.,
              where the processing is based on our legitimate interests.
            </l>{" "}
            <l>
              {" "}
              The right to have any information you provided to us on an
              automated basis returned to you in a structured, commonly used,
              and machine-readable format or sent directly to another
              organization, where technically feasible (“data portability”).{" "}
            </l>
            <l>
              {" "}
              Where the processing of your information is based on your consent,
              the right to withdraw that consent is subject to legal or
              contractual restrictions.
            </l>
            <l>
              {" "}
              The right to object to any decisions based on the automated
              processing of your personal data, including profiling, and the
              right to lodge a complaint with the supervisory authority
              responsible for data protection matters.
            </l>{" "}
            Please note that if you request a copy of your information, you may
            be required to pay a statutory fee. If we have any information about
            you that is incorrect or if there are any changes to your details,
            please let us know so that we can keep our records accurate and
            up-to-date.
          </ul>
        </div>
        <div className="grid">
          <Typography variant="h4" className="py-2">
            NOTIFICATION OF CHANGES TO PRIVACY POLICY
          </Typography>
          <Typography variant="paragraphy">
            We review this privacy policy regularly and reserve the right to
            make changes at any time to take account of changes in our business
            and legal requirements. The policy on this page and such revised
            policies become effective as of the time they are posted. Hence, our
            privacy policies will be dated to reflect the most recent update.
          </Typography>
        </div>
        <div className="grid">
          <Typography variant="h4" className="py-2">
            CONTACT
          </Typography>
          <Typography variant="paragraphy">
            We are dedicated to protecting your privacy. If you have any
            questions or comments regarding this policy or any complaints
            concerning our compliance with it, please contact our Data
            Protection Officer at help@focusmfb.com. We will respond promptly to
            requests, questions, or concerns.
          </Typography>
        </div>
      </div>
    </>
  );
}

export default Privacy;
