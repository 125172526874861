import { NavbarSimple } from "./Navbar";

export function Header() {
  return (
    <>
      <div id="home" className="static">
        <NavbarSimple />
      </div>
    </>
  );
}
