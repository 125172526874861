export class Util {
  static formatPhoneNumber(phoneNumber) {
    let pNumber;

    if (phoneNumber.startsWith("+234") && phoneNumber.length === 14)
      pNumber = `0${phoneNumber.substring(4, phoneNumber.length)}`;
    else if (phoneNumber.startsWith("234") && phoneNumber.length === 13)
      pNumber = `0${phoneNumber.substring(3, phoneNumber.length)}`;
    else if (!phoneNumber.startsWith("0") && phoneNumber.length === 10)
      pNumber = `0${phoneNumber}`;
    else pNumber = phoneNumber;
    return pNumber;
  }
  static formatPhoneNumberShort(phoneNumber) {
    let pNumber;

    if (phoneNumber.startsWith("+234") && phoneNumber.length === 14)
      pNumber = `${phoneNumber.substring(4, phoneNumber.length)}`;
    else if (phoneNumber.startsWith("234") && phoneNumber.length === 13)
      pNumber = `${phoneNumber.substring(3, phoneNumber.length)}`;
    else if (!phoneNumber.startsWith("0") && phoneNumber.length === 11)
      pNumber = `${phoneNumber.substring(1, phoneNumber.length)}`;
    else pNumber = phoneNumber;
    return pNumber;
  }
  static sanitizePhoneNumber(phoneNumber) {
    if (phoneNumber.startsWith("+"))
      return phoneNumber.substring(1, phoneNumber.length);
    return phoneNumber;
  }

  static formatAsCurrency = (number) => {
    return Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(number);
  };

  static nairaToKobo = (amount) => {
    if (amount == null) return "100";
    return amount + "00";
  };

  static convertStringToNumber = (string) => {
    return parseInt(string);
  };
  static formatDate = (string) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };
    return new Intl.DateTimeFormat("en", options).format(Date.parse(string));
  };
  static getTime = (string) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Intl.DateTimeFormat("en", options).format(Date.parse(string));
  };

  static formatError(error) {
    console.log(error);
    if (error.response) {
      if (error.response.data) {
        if (error?.response.data.statusDescription) {
          return error?.response.data.statusDescription;
        }
        return error?.response.data;
      }
      return error?.response;
    }
    return error.message;
  }
  static getToken() {
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      return parsedUser?.idToken;
    }
    return "";
  }
}
