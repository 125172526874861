import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Button,
  Spinner,
} from "@material-tailwind/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import logo from "./../../logo.png";
import { useLogin } from "./useLogin";
import { useForm } from "react-hook-form";
import FormInputField from "../Util/FormInputField";
import { useState } from "react";
function Login() {
  const [show, setShow] = useState(false);
  const { login, isPending } = useLogin();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  function onSubmit({ email, password }) {
    console.log({ email, password });
    login(
      { email, password },
      {
        onSettled: () => reset(),
      }
    );
  }
  return (
    <Card className="w-96 mx-auto my-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader className="relative h-56 mt-0 shadow-none">
          <img src={logo} className="h-56 mx-auto" alt="logo" />
        </CardHeader>
        <CardBody className="flex flex-col gap-4">
          <FormInputField title="Email Address" error={errors?.email?.message}>
            <Input
              {...register("email", {
                required: "Email Address field is required",
              })}
              size="lg"
              disabled={isPending}
              className="rounded !border !border-gray-300 !border-t-blue-gray-200 bg-white text-secondary shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:ring-gray-900/10"
              placeholder={"Enter your Email Address "}
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
          </FormInputField>
          <FormInputField title="password" error={errors?.password?.message}>
            <Input
              {...register("password", {
                required: "Password field is required",
                minLength: {
                  value: 6,
                  message: "Password must be 6 digits characters",
                },
              })}
              type={show ? "tel" : "password"}
              icon={
                show ? (
                  <EyeIcon
                    className="fill-primary"
                    onClick={() => setShow(false)}
                  />
                ) : (
                  <EyeSlashIcon
                    className="fill-primary"
                    onClick={() => setShow(true)}
                  />
                )
              }
              size="lg"
              disabled={isPending}
              className="rounded !border !border-gray-300 !border-t-blue-gray-200 bg-white text-secondary shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:ring-gray-900/10"
              placeholder={"Enter your password "}
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
          </FormInputField>
        </CardBody>
        <CardFooter className="pt-0">
          <Button
            disabled={isPending}
            type="submit"
            variant="gradient"
            fullWidth
          >
            {isPending ? <Spinner /> : "Sign In"}
          </Button>
          <Typography variant="small" className="mt-6 flex justify-center">
            Don&apos;t have an account?
            <Typography
              as="a"
              href="#signup"
              variant="small"
              color="blue-gray"
              className="ml-1 font-bold"
            >
              Sign up
            </Typography>
          </Typography>
        </CardFooter>
      </form>
    </Card>
  );
}
export default Login;
