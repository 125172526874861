import { Breadcrumbs, Typography } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";

export function FeaturedImage({ title }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <figure className="top-0 relative h-96 w-full text-[#1b2441]">
      <img
        className="h-full w-full rounded-none object-cover object-center"
        src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80"
        alt="nature"
      />
      <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/75  px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
        <Breadcrumbs className="bg-transparent">
          <a href="/" className="opacity-60">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
          </a>
          <a href="/" className="opacity-60">
            <span>Home</span>
          </a>
          <Typography
            onClick={() => navigate(location.pathname)}
            className="active capitalize"
          >
            {location.pathname.substring(
              location.pathname.lastIndexOf("/") + 1
            )}
          </Typography>
        </Breadcrumbs>
        <Typography variant="h5" className=" my-auto py-1/2">
          {title}
        </Typography>
      </figcaption>
    </figure>
  );
}
