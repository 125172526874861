import { Typography } from "@material-tailwind/react";
import { FeaturedImage } from "./widgets/FeaturedImage";

function TermsAndCondition() {
  return (
    <>
      <FeaturedImage title="Focus Microfinance Bank Terms & Conditions Agreement" />
      <div className="gap-5 grid  px-20 py-5  mx-auto text-[#1b2441]">
        <div className="grid">
          <Typography variant="h4" className="py-2">
            Focus Microfinance Bank Website Access Agreement
          </Typography>
          <Typography variant="paragraphy">
            Please read this Agreement carefully before accessing the Focus
            Microfinance Bank Website. As a condition to and in consideration of
            receiving and accessing the Focus Microfinance Bank Website, the
            User agrees to be bound by the terms of this Agreement. Use of or
            access to the Focus Microfinance Bank Website shall constitute
            acceptance of and agreement to be bound by this Agreement. If you do
            not wish to be bound by this agreement, do not access The Focus
            Microfinance Bank Website. If you have any questions about this
            Agreement, please contact us via e-mail at{" "}
            <a href="mailto:help@focusmfb.com">help@focusmfb.com.</a>
          </Typography>{" "}
          <Typography variant="paragraphy">
            THIS AGREEMENT "Agreement" is entered into by and between Focus
            Microfinance Bank and any individual, corporation, association,
            agency, company, or other entity who accesses or uses the Focus
            Microfinance Bank Website (the "User" or "you"). Focus Microfinance
            Bank Website, which is provided without charge to you, is a World
            Wide Web site on the Internet that is designed to allow Focus
            Microfinance Bank to communicate with users and for you to interact
            with those users. Focus Microfinance Bank Website is owned and
            operated by Focus Microfinance Bank. The Focus Microfinance Bank
            Website contains or may contain information, communications,
            opinions, text, graphics, links, electronic art, animations, audio,
            video, software, photos, music, sounds and other material and data
            (collectively, "Content") formatted, organized and collected in a
            variety of forms that are generally accessible to Users, including
            directories and databases, and areas of the Focus Microfinance Bank
            Website that can be modified by Users, such as posting classifieds,
            uploading multimedia files, registering user profiles, and creating
            auto-notify, personalized pages, and personalized project areas.
          </Typography>
          <div className="grid">
            <Typography variant="lead" className="py-5">
              1.1 Access to the Focus Microfinance Bank Website
            </Typography>
            <Typography>
              You are responsible for providing all hardware, software,
              telephone or other communications equipment and/or service to
              connect to the Internet and access the Focus Microfinance Bank
              Website and are responsible for all Internet access charges,
              telephone charges or other fees or charges incurred in connecting
              to the Internet and accessing the Focus Microfinance Bank Website.
            </Typography>
            <Typography variant="lead" className="py-5">
              1.2 User Conduct
            </Typography>
            <Typography>
              You agree to access and use the Focus Microfinance Bank Website
              only for lawful purposes. You are solely responsible for the
              knowledge of and adherence to any and all laws, statutes, rules
              and regulations, pertaining to{" "}
              <ol className="py-3">
                <li>
                  (i) your use of the Focus Microfinance Bank Website, including
                  any Interactive Area,
                </li>{" "}
                <li>
                  (ii) the use of any networks or other services connected to
                  the Focus Microfinance Bank plc Website, and{" "}
                </li>
                <li>
                  (iii) the communications means by which you connect your
                  modem, computer, or other equipment to the Focus Microfinance
                  Bank Website.
                </li>
              </ol>{" "}
              By accessing the Focus Microfinance Bank Website, you agree that
              you will not:
            </Typography>
            <ul className="list-disc pl-10">
              <li>
                Restrict or inhibit any other user from using and enjoying the
                Interactive Features{" "}
              </li>{" "}
              <li>
                Post or transmit any unlawful, threatening, abusive, libelous,
                defamatory, obscene, vulgar, pornographic, profane, or indecent
                information of any kind, including without limitation any
                transmissions constituting or encouraging conduct that would
                constitute a criminal offence, give rise to civil liability or
                otherwise violate any local, state, national, or international
                law;{" "}
              </li>{" "}
              <li>
                {" "}
                Post or transmit any information, software, or other material
                which violates or infringes in the rights of others, including
                material which is an invasion of privacy or publicity rights or
                which is protected by copyright, trademark or other proprietary
                right, or derivative works with respect thereto, without first
                obtaining permission from the owner or right holder.
              </li>{" "}
              <li>
                Post or transmit any information, software or other material
                which contains a virus or other harmful component;{" "}
              </li>{" "}
              <li>
                Alter, damage or delete any Content or other communications that
                are not your own Content or to otherwise interfere with the
                ability of others to access the Focus Microfinance Bank Website;{" "}
              </li>{" "}
              <li>
                Disrupt the normal flow of communication in an Interactive Area;{" "}
              </li>{" "}
              <li>
                Claim a relationship with or to speak for any business,
                association, institution or other organization for which you are
                not authorized to claim such a relationship;{" "}
              </li>{" "}
              <li>
                Violate any operating rule, policy or guideline of your Internet
                access provider or online service.{" "}
              </li>
            </ul>
          </div>
          <div className="grid">
            <Typography variant="h4" className="py-2">
              2. Intellectual Property Rights
            </Typography>
            <Typography variant="lead" className="py-5">
              2.1 Focus Microfinance Bank Website Content
            </Typography>{" "}
            <Typography>
              Focus Microfinance Bank Website Content (a) You acknowledge that
              Content on the Focus Microfinance Bank Website is generally
              provided by Focus Microfinance Bank, individual contributors of
              Content ("Contributors"), third party licensees, and/or other
              Users. You acknowledge that the Focus Microfinance Bank Website
              permits access to Content that is protected by copyrights,
              trademarks, and other proprietary (including intellectual
              property) rights ("Intellectual Property Rights"), and that these
              Intellectual Property Rights are valid and protected in all media
              existing now or later developed and except as is explicitly
              provided below, your use of Content shall be governed by
              applicable copyright and other intellectual property laws. You
              acknowledge that the Focus Microfinance Bank Website owns a
              copyright in the "look and feel," i.e., the selection,
              coordination, arrangement and presentation of such Content. (b)
              You may not modify, copy, reproduce, transmit, distribute,
              publish, create derivative works from, display or otherwise
              transfer or commercially exploit any of the Content, in whole or
              in part, provided, however, that you may (i) make a reasonable
              number of digital or other form of copies to permit your computer
              hardware and software to access and view the Content, (ii) print
              one copy of each piece of Content, (iii) make and distribute a
              reasonable number of copies of Content, in whole or in part, in
              hard copy or electronic form for internal use only. Any permitted
              copies of Content must reproduce in an unmodified form any notices
              contained in the Content, such as all Intellectual Property Right
              notices, and an original source attribution to "The Focus
              Microfinance Bank Website" and its URL address. You acknowledge
              that the Focus Microfinance Bank Website, its Contributors, and/or
              Users remain the owners of the Content and that you do not acquire
              any Intellectual Property Rights by downloading or printing
              Content.
            </Typography>
            <Typography variant="lead" className="py-5">
              2.2 Content Provided by User
            </Typography>{" "}
            <Typography variant="paragraph" className="py-2">
              You may upload to any Interactive Area or otherwise transmit,
              post, publish, reproduce or distribute, on or through the Focus
              Microfinance Bank Website only Content that is not subject to any
              Intellectual Property Rights, or Content in which any holder of
              Intellectual Property Rights has given express authorization for
              distribution over the Internet and on the Focus Microfinance Bank
              plc Website, without restriction whatsoever. Any Content submitted
              with the consent of a copyright owner other than you should
              contain a phrase such as "Copyright owned by [name of owner]; Used
              by Permission." By submitting Content to any Interactive Area, you
              automatically grant and/or warrant that the owner of such Content,
              whether it be You or a third party, has expressly granted to Focus
              Microfinance Bank the royalty-free, perpetual, irrevocable,
              non-exclusive, unrestricted, worldwide right and license to use,
              reproduce, modify, adapt, publish, translate, create derivative
              works from, sublicense, distribute, perform, and display such
              Content, in whole or in part, worldwide and/or to incorporate it
              in other works in any form, media, or technology now known or
              later developed for the full term of any Intellectual Property
              Rights that may exist in such Content. You also permit Focus
              Microfinance Bank to sublicense to third parties the unrestricted
              right to exercise any of the foregoing rights granted with respect
              to such Content. You also permit any User to access, view, store
              and reproduce the Content for personal use.
            </Typography>{" "}
          </div>
          <div>
            <Typography variant="h4" className="py-5">
              3. Interactive Areas
            </Typography>{" "}
            <Typography variant="paragraph" className="py-2">
              You acknowledge that the Focus Microfinance Bank Website may
              include various interactive areas ("Interactive Areas"), including
              but not limited to classifieds. These Interactive Areas allow
              feedback to the Focus Microfinance Bank Website and real-time
              interaction between users. You further understand that Focus
              Microfinance Bank does not control the messages, information, or
              files delivered to such Interactive Areas and that the Focus
              Microfinance Bank Website may offer you and other Users the
              capability of creating and managing an Interactive Area. However,
              neither Focus Microfinance Bank, its subsidiary and parent
              companies, or affiliates, nor their respective directors,
              officers, employees and agents are responsible for Content within
              any Interactive Area. Your use and/or management of an Interactive
              Area will be governed by this Agreement and any additional rules
              or operating procedures of any Interactive Area established by you
              or another User, as applicable. You recognize that Focus
              Microfinance Bank cannot, and does not intend to, screen
              communications in advance. Moreover, because the Focus
              Microfinance Bank Website encourages open and candid communication
              in the Interactive Areas, Focus Microfinance Bank cannot determine
              in advance the accuracy or conformance to this Agreement of any
              Content transmitted in an Interactive Area. The Focus Microfinance
              Bank Website is not responsible for screening, policing, editing,
              reviewing or monitoring any Content in an Interactive Area.
              Notwithstanding the above, you agree that Focus Microfinance Bank
              has the right to monitor any Interactive Area, from time to time
              and to disclose any information as necessary to satisfy any law,
              regulation or other governmental request, to operate the
              Interactive Area, or to protect itself or other Users. If notified
              of Content that is alleged not to conform to this Agreement, Focus
              Microfinance Bank may investigate the allegation and determine in
              its sole discretion whether to remove or request the User to
              remove such Content. Focus Microfinance Bank reserves the right to
              prohibit conduct, communication or Content within an Interactive
              Area, or to edit, refuse to post, or to remove any Content, in
              whole or in part, which it deems in its sole discretion to
            </Typography>{" "}
            <ol className="pl-5">
              <li>
                (i) violate the then-standard provisions of this Agreement or
                any other standard, written Focus Microfinance Bank Website
                policy in effect at that time,{" "}
              </li>
              <li>
                (ii) be harmful to the rights of any User, Focus Microfinance
                Bank, or other third parties,{" "}
              </li>
              <li>(iii) violate applicable law, or</li>
              <li>(iv) be otherwise objectionable.</li>
            </ol>
          </div>
          <div>
            <Typography variant="h4" className="py-5">
              4. Termination
            </Typography>{" "}
            <Typography variant="paragraph" className="py-2">
              The only right with respect to dissatisfaction with any policies,
              guidelines, or practices of Focus Microfinance Bank Limited in
              operating the Focus Microfinance Bank Website, or any change in
              Content, is for you to discontinue accessing the Focus
              Microfinance Bank Website. Focus Microfinance Bank may terminate
              or temporarily suspend your access to all or any part of the Focus
              Microfinance Bank Website, without notice, for conduct that Focus
              Microfinance Bank believes is a violation of this Agreement or any
              policies or guidelines posted by Focus Microfinance Bank, or for
              other conduct which Focus Microfinance Bank believes, in its sole
              discretion, is harmful to Focus Microfinance Bank or other Users.
              Focus Microfinance Bank may discontinue operating the Focus
              Microfinance Bank Website and terminate this Agreement without
              notice at any time for any reason in its sole discretion. In the
              event of termination, you are no longer authorized to access the
              Focus Microfinance Bank Website, including the Interactive Areas,
              and the restrictions imposed on you with respect to Content
              downloaded from the Focus Microfinance Bank Website, as well as
              the disclaimers and limitations of liabilities set forth in this
              agreement, shall survive.
            </Typography>
          </div>
          <div>
            <Typography variant="h4" className="py-5">
              5. Links; Disclaimers of Warranties; Limitations of Liability
            </Typography>{" "}
            <Typography variant="lead" className="py-1">
              5.1 Links.
            </Typography>{" "}
            <Typography variant="paragraph" className="py-2">
              You understand that except for Content, products or services
              expressly available at the Focus Microfinance Bank Website,
              neither Focus Microfinance Bank, its subsidiary and parent
              companies, or affiliates, or their respective directors, officers,
              employees, and agents controls, provides, or is responsible for
              any Content, goods or services available through sites on the
              Internet linked to or from the Focus Microfinance Bank Website.
              All such Content, goods and services are made accessible on the
              Internet by independent third parties and are not part of the
              Focus Microfinance Bank Website or controlled by Focus
              Microfinance Bank. Focus Microfinance Bank neither endorses nor is
              responsible for the accuracy, completeness, usefulness, quality or
              availability of any Content, goods or services available on any
              site linked to or from the Focus Microfinance Bank Website, which
              are the sole responsibility of such independent third parties, and
              your use thereof is solely at your own risk. Neither Focus
              Microfinance Bank, its subsidiary and parent companies, or
              affiliates, or their respective directors, officers, employees,
              and agents shall be held responsible or liable, directly or
              indirectly, for any loss or damage caused or alleged to have been
              caused by your use of or reliance on any Content, goods or
              services available on any site linked to or from the Focus
              Microfinance Bank Website or your inability to access the Internet
              or any site linked to or from the Focus Microfinance Bank Website.
            </Typography>
          </div>
          <div>
            <Typography variant="h4" className="py-5">
              6. Indemnity
            </Typography>{" "}
            <Typography variant="paragraph" className="py-2">
              You agree to indemnify and hold Focus Microfinance Bank, its
              parent or subsidiary companies and their affiliates, and their
              respective directors, officers, employees, and agents from any and
              all liabilities, claims and expenses, including reasonable
              attorney's fees, arising from breach of this Agreement, any other
              policy, your use or access of the Focus Microfinance Bank Website
              or any Internet site linked to or from the Focus Microfinance Bank
              Website, or in connection with the transmission of any Content on
              the Focus Microfinance Bank Website.
            </Typography>
          </div>
          <div>
            <Typography variant="h4" className="py-5">
              7. Miscellaneous
            </Typography>{" "}
            <Typography variant="paragraph" className="py-2">
              This Agreement comprises the entire agreement between Focus
              Microfinance Bank and you, and supersedes any prior agreements
              with respect to the subject matter herein. Focus Microfinance Bank
              may revise this Agreement or any other policy at any time and from
              time to time, and such revision shall be effective two (2) days
              upon posting notice of such revision prominently on the Focus
              Microfinance Bank Website. You agree to review this Agreement
              periodically to be aware of such revisions. If any such revision
              is unacceptable to you, you must discontinue accessing the Focus
              Microfinance Bank Website.
            </Typography>
            <Typography variant="paragraph" className="py-2">
              Your continued accessing and use of the Focus Microfinance Bank
              Website following notice of any such revision shall conclusively
              be deemed acceptance of all such revisions. The provisions of
              Sections 1.2, 2.1, 2.2, 5.1, 5.2, 5.3, 6 and 7 shall survive the
              termination or expiration of this Agreement. If any provision of
              this Agreement or any other policy be held invalid or
              unenforceable, that portion shall be construed in accordance with
              applicable law as nearly as possible to reflect the original
              intention of the parties and the remaining portions will continue
              in full force and effect. The failure of Focus Microfinance Bank
              to insist upon or enforce strict performance of any provision of
              this Agreement shall not be construed as a waiver of any provision
              or right. This Agreement shall be governed by the laws of the
              state or province of domicile of Focus Microfinance Bank,
              excluding its conflict of laws rules, and you and the Focus
              Microfinance Bank Website each submit to the exclusive
              jurisdiction of the courts of that state or province.
            </Typography>
            <Typography variant="paragraph" className="py-2">
              This Agreement is personal to you and you may not assign your
              rights or obligations thereunder to anyone. All logos, brand
              names, products, trademarks and service marks appearing herein may
              be the trademarks or service marks of their respective owners.
              References to any trademark, service mark and links to or from the
              Focus Microfinance Bank Website have been done strictly for
              clarification and identification and does not constitute
              endorsement by Focus Microfinance Bank of the products, services
              or information offered by the owner of such trademark, service
              mark or link or endorsement of Focus Microfinance Bank by such
              trademark, service mark or link owner.
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndCondition;
