import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  Button,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import logo from "./../../logo.png";
import { Link, Outlet } from "react-router-dom";
import { useLogout } from "./useLogout";
function MenuLayout() {
  return (
    <div className="bg-white h-full">
      <NavbarSimple />
      <Outlet />
    </div>
  );
}

export default MenuLayout;

function NavList() {
  const { logout } = useLogout();
  //function logOut() {}
  return (
    <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <Link
          to="query-bvn"
          className="flex items-center hover:text-blue-500 transition-colors"
        >
          Query BVN
        </Link>
      </Typography>
      <Button onClick={logout} variant="outlined" className="p-1 font-medium">
        Sign Out
      </Button>
    </ul>
  );
}

export function NavbarSimple() {
  const [openNav, setOpenNav] = useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Navbar className="mx-auto px-0 max-w-full py-3 px-5 rounded-none">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Link to="">
          <img
            src={logo}
            className="size-16 ml-4 cursor-pointer py-1.5"
            alt="logo"
          />
        </Link>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
}
