import axios from "axios";
import { Util } from "../Util/Utils";
//import { store } from '../store/store'

const axiosInstance = axios.create({
  baseURL: "https://apps.better-ng.com/api/v2/",
});

axiosInstance.interceptors.request.use((config) => {
  const token = Util.getToken();
  console.log(token);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
