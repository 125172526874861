import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Typography } from "@material-tailwind/react";

function FormInputField({ title, hint, error, children }) {
  return (
    <label className="mt-5 block">
      <span className="text-gray-700 place-content-left">{title}</span>
      <div className="relative flex w-full max-w-96">{children}</div>
      {error && (
        <Typography variant="paragraph" color="red" className="text-xs">
          {error}
        </Typography>
      )}
      {hint && (
        <div className="mt-2 flex flex-row bg-blue-50 px-3 py-2">
          <InformationCircleIcon className="size-6 text-gray-800" />
          <p className="px-1 text-xs">{hint}</p>
        </div>
      )}
    </label>
  );
}
export default FormInputField;
