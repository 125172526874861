import { Card, CardBody, Typography } from "@material-tailwind/react";

export function ProductCard({ icon, title, message, handleOnClick, url }) {
  return (
    <Card
      className="w-full mt-3 lg:w-1/4 transition delay-150 duration-300 ease-in-out hover:scale-105"
      onClick={handleOnClick}
    >
      <CardBody>
        <img src={icon} alt={title} className="size-20 my-5" />
        <Typography
          variant="h4"
          color="blue-gray"
          className="mb-1 text-secondary"
        >
          <a href={url}>{title}</a>
        </Typography>
        <Typography className="text-md text-secondary">{message}</Typography>
      </CardBody>
    </Card>
  );
}
