import { Util } from "../Util/Utils";
import axiosInstance from "./AxiosInstance";

export async function loginAction({ email, password }) {
  console.log({ email, password });
  const response = await axiosInstance.post(`auth/login`, {
    email,
    password,
  });
  if (response.status === 200) {
    saveTokenInLocalStorage(response.data.data);
    return response.data;
  }
  throw new Error(response.data);
}
export async function getCurrentUser() {
  const storedUser = localStorage.getItem("user");
  if (!storedUser)
    throw new Error("Session has expired! Please Login to continue");
  return JSON.parse(storedUser);
}
export function saveTokenInLocalStorage({ idToken, expiresIn, user }) {
  expiresIn = new Date(
    new Date().getTime() + parseInt(expiresIn) * 1000
  ).toISOString();
  localStorage.setItem(
    "user",
    JSON.stringify({
      idToken,
      expiresIn,
      user,
    })
  );
}

export async function getBvn({ bvn }) {
  const token = Util.getToken();
  axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const response = await axiosInstance.get(`/user/query-bvn/${bvn}`);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data);
}

export function logout() {
  localStorage.setItem("user", null);
}
