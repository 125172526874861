import { Button, Input, Typography } from "@material-tailwind/react";

function NewLetter() {
  return (
    <div className="grid px-5 text-black lg:px-20 py-10 lg:my-20">
      <div className="mx-auto w-full lg:w-3/5">
        <Typography variant="h2" className=" mx-auto  text-center">
          Stay updated with Focus MFB by signing up for our newsletter
        </Typography>
      </div>
      <div className="flex gap-10 mx-auto w-full lg:w-2/5 my-5">
        <Input
          type="text"
          size="lg"
          className="rounded !border !border-gray-300 !border-t-blue-gray-200 bg-white text-secondary shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:ring-gray-900/10"
          placeholder="Enter your input"
          labelProps={{
            className: "before:content-none after:content-none",
          }}
          containerProps={{
            className: "min-w-0",
          }}
        />
        <div className="w-1/3">
          <Button fullWidth size="lg" className="bg-orange-600 text-white">
            Subscribe
          </Button>
        </div>
      </div>
      <div className="mx-auto w-full lg:w-3/5 my-5 px-10">
        <Typography
          variant="paragrahy"
          className=" mx-auto text-center text-lg"
        >
          By subscribing you agree to our Privacy Policy and provide consent to
          receive updates from our company.
        </Typography>
      </div>
    </div>
  );
}

export default NewLetter;
