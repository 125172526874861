import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Util } from "../Util/Utils";
import { logout as logoutAction } from "../services/AuthServices";
import { useNavigate } from "react-router-dom";

export const useLogout = () => {
  const navigate = useNavigate();
  const { mutate: logout, isPending } = useMutation({
    mutationFn: logoutAction,
    onSuccess: () => {
      navigate("/login");
    },
    onError: (error) => {
      console.error(error);
      toast.error(Util.formatError(error));
    },
  });
  return { logout, isPending };
};
