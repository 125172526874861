import MissionAndVision from "./widgets/MissionAndVision";
import Products from "./widgets/Products";
import Community from "./widgets/Community";
import NewLetter from "./widgets/NewLetter";
import office from "../assets/office.jpeg";
import customer from "../assets/customer.jpeg";
import market from "../assets/market.jpeg";
import { Button, Carousel, Typography } from "@material-tailwind/react";
function Home() {
  return (
    <>
      <Carousel
        transition={{ duration: 2 }}
        autoplay={true}
        loop={true}
        className="top-0 rounded-none"
      >
        <div className=" h-full w-full">
          <img
            src={office}
            alt="focusMFB-Office"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 grid w-3/4 lg:h-full w-full items-center bg-black/20">
            <div className="lg:w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
              <Typography
                variant="h1"
                color="white"
                className="text-xl md:text-4xl"
              >
                Focus Microfinance Bank
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="p-0 text-sm lg:mb-12 lg:text-2xl"
              >
                we are dedicated to driving financial inclusion and empowering
                individuals and small businesses to achieve economic growth and
                prosperity.
              </Typography>
              <div className="flex gap-2">
                <a href="https://focusmfb.qoreonline.com/onboarding/type">
                  <Button className="py-2 px-4 bg-orange-600 text-white lg:py-3.5 lg:px-7 ">
                    Open Account
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-full w-full">
          <img
            src={customer}
            alt="FocusMFB-customer"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 grid w-3/4 lg:h-full w-full items-center bg-black/20">
            <div className="lg:w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
              <Typography
                variant="h1"
                color="white"
                className="text-xl md:text-4xl"
              >
                Focus Microfinance Bank
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="p-0 text-sm lg:mb-12 lg:text-2xl"
              >
                we are dedicated to driving financial inclusion and empowering
                individuals and small businesses to achieve economic growth and
                prosperity.
              </Typography>
              <div className="flex gap-2">
                <a href="https://focusmfb.qoreonline.com/onboarding/type">
                  <Button className="py-2 px-4 bg-orange-600 text-white lg:py-3.5 lg:px-7 ">
                    Open Account
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-full w-full">
          <img
            src={market}
            alt="FocusMFB-market"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 grid w-3/4 lg:h-full w-full items-center bg-black/20">
            <div className="lg:w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
              <Typography
                variant="h1"
                color="white"
                className="text-xl md:text-4xl"
              >
                Focus Microfinance Bank
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="p-0 text-sm lg:mb-12 lg:text-2xl"
              >
                we are dedicated to driving financial inclusion and empowering
                individuals and small businesses to achieve economic growth and
                prosperity.
              </Typography>
              <div className="flex gap-2">
                <a href="https://focusmfb.qoreonline.com/onboarding/type">
                  <Button className="py-2 px-4 bg-orange-600 text-white lg:py-3.5 lg:px-7 ">
                    Open Account
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      <MissionAndVision />
      <Products />
      <Community />
      <NewLetter />
    </>
  );
}

export default Home;
