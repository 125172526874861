import { Typography } from "@material-tailwind/react";
import { ProductCard } from "./ProductCard";
import money from "../../assets/money.png";
import paperplane from "../../assets/paperplane.png";
import person from "../../assets/person.png";
import security from "../../assets/security.png";

function Products() {
  return (
    <div id="products" className="grid text-black lg:px-20 py-10 lg:my-20">
      <div className="mx-auto">
        <Typography variant="h2" className="px-5 mx-auto lg:w-3/5  text-center">
          Presenting Products and Services that are right for you
        </Typography>
      </div>
      <div className="grid gap-5 p-5 lg:flex lg:gap-5">
        <ProductCard
          icon={person}
          title="Open Account"
          message="We provide secure and accessible savings accounts that encourage individuals to develop a culture of saving."
          url="https://focusmfb.qoreonline.com/onboarding/type"
        />
        <ProductCard
          icon={paperplane}
          title="Quick Transfer"
          message="Our transfer services enable individuals to send and receive money quickly and securely."
          url="https://focusmfb.qoreonline.com/login"
        />
        <ProductCard
          icon={security}
          title="Insurance"
          message="We offer insurance products tailored for the unique needs of our clients, providing them with protection and financial security"
          url="#"
        />
        <ProductCard
          icon={money}
          title="Loans"
          message="We offer small loans with flexible repayment terms to support the entrepreneurial aspirations of individuals andmicroenterprises."
          url="http://getloan.focusmfb.com/"
        />
      </div>
    </div>
  );
}

export default Products;
